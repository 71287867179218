import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import SubmissionSpinner from '../components/SubmissionSuccess/SubmissionSpinner';

const GreatForm = () => {
  const [Ispublished, setIspublished] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [applicationErrors, setApplicationErrors] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);

  // Personal details
  const [fullname, setfullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [sex, setSex] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');

  // Qualifications
  const [qualifications, setQualifications] = useState([]);
  const [newQualification, setNewQualification] = useState({ degree: '', institution: '', graduationYear: '' });

  // Experience
  const [experiences, setExperiences] = useState([]);
  const [newExperience, setNewExperience] = useState({ job: '', company: '', manager: '', duration: '' });

  // References
  const [references, setReferences] = useState([]);
  const [newReference, setNewReference] = useState({ fullname: '', email: '', position: '', organisation: '', contactType: '', contact: '' });

  // Requirements (mock data for checkboxes)
  const [selectedRequirements, setSelectedRequirements] = useState([]);

  // Document Upload
  const [document, setDocument] = useState(null);

  // Ref to scroll to missing fields alert
  const missingFieldsRef = useRef(null);

  // Handlers for dynamic sections
  const handleAddQualification = () => {
    setQualifications([...qualifications, newQualification]);
    setNewQualification({ degree: '', institution: '', graduationYear: '' });
  };

  const handleAddExperience = () => {
    setExperiences([...experiences, newExperience]);
    setNewExperience({ job: '', company: '', manager: '', duration: '' });
  };

  const handleAddReference = () => {
    setReferences([...references, newReference]);
    setNewReference({ fullname: '', email: '', position: '', organisation: '', contactType: '', contact: '' });
  };

  const handleRequirementChange = (event) => {
    const { value } = event.target;
    if (selectedRequirements.includes(value)) {
      setSelectedRequirements(selectedRequirements.filter(req => req !== value));
    } else {
      setSelectedRequirements([...selectedRequirements, value]);
    }
  };

  const handleDocumentChange = (event) => {
    setDocument(event.target.files[0]);
  };

  const handleSubmit = () => {
    // Create FormData object
    const formData = new FormData();
    formData.append('fullname', fullname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('sex', sex);
    formData.append('gender', gender);
    formData.append('dob', dob);
    formData.append('qualifications', JSON.stringify(qualifications));
    formData.append('experiences', JSON.stringify(experiences));
    formData.append('references', JSON.stringify(references));
    formData.append('requirements', JSON.stringify(selectedRequirements));

    if (document) {
      formData.append('document', document);
    }

    // Log FormData for debugging purposes
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    // Example: Post FormData to API endpoint
    // fetch('your-api-endpoint', {
    //   method: 'POST',
    //   body: formData
    // }).then(response => response.json())
    //   .then(data => console.log(data))
    //   .catch(error => console.error('Error:', error));

    // You might want to set loading state here
    setIsLoadingg(true);

    // Reset loading state after submission (or handle accordingly)
    setIsLoadingg(false);
  };

  return (
    <>
      {Ispublished ? (
        <p>Please fill in the following required field(s):</p>
      ) : (
        <div ref={missingFieldsRef} className="container mt-5 mb-20">
          {missingFields.length > 0 && (
            <div className="alert alert-danger mt-3">
              <p>Please fill in the following required field(s):</p>
              <ul>
                {missingFields.map((field, index) => (
                  <li key={index}>{field}</li>
                ))}
              </ul>
            </div>
          )}

          {errorMessage && (
            <div className="alert alert-danger fw-bold">
              <i className="bi bi-info-circle-fill"></i>
              <p>{errorMessage}</p>
            </div>
          )}

          {isLoadingg && (
            <div className="loading-overlay">
              <SubmissionSpinner />
            </div>
          )}

          {/* Personal Details Section */}
          <PageTitle icon="bi bi-envelope" title="Job Application Form" />

          <div className="card shadow border-0 rounded">
            <div className="card-header fw-medium">Personal Details</div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mt-3">
                  <label>Full Name</label>
                  <input type="text" className="form-control" value={fullname} onChange={(e) => setfullname(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Email</label>
                  <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Phone</label>
                  <input type="tel" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Sex</label>
                  <input type="text" className="form-control" value={sex} onChange={(e) => setSex(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Gender</label>
                  <input type="text" className="form-control" value={gender} onChange={(e) => setGender(e.target.value)} required />
                </div>
                <div className="col-md-6 mt-3">
                  <label>Date of Birth</label>
                  <input type="date" className="form-control" value={dob} onChange={(e) => setDob(e.target.value)} required />
                </div>
              </div>
            </div>
          </div>

          {/* Qualifications Section */}
          <div className="card shadow border-0 rounded mt-4">
            <div className="card-header fw-medium">Qualifications</div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <label>Degree</label>
                  <input type="text" className="form-control" value={newQualification.degree} onChange={(e) => setNewQualification({ ...newQualification, degree: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Institution</label>
                  <input type="text" className="form-control" value={newQualification.institution} onChange={(e) => setNewQualification({ ...newQualification, institution: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Graduation Year</label>
                  <input type="number" className="form-control" value={newQualification.graduationYear} onChange={(e) => setNewQualification({ ...newQualification, graduationYear: e.target.value })} />
                </div>
                <div className="col-12 mt-3">
                  <button className="btn btn-secondary" onClick={handleAddQualification}>Add Qualification</button>
                </div>
              </div>

              {/* Display Added Qualifications */}
              {qualifications.length > 0 && (
                <ul className="list-group mt-3">
                  {qualifications.map((qual, index) => (
                    <li key={index} className="list-group-item">{qual.degree} from {qual.institution} ({qual.graduationYear})</li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Experience Section */}
          <div className="card shadow border-0 rounded mt-4">
            <div className="card-header fw-medium">Experience</div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <label>Job</label>
                  <input type="text" className="form-control" value={newExperience.job} onChange={(e) => setNewExperience({ ...newExperience, job: e.target.value })} />
                </div>
                <div className="col-md-3">
                  <label>Company</label>
                  <input type="text" className="form-control" value={newExperience.company} onChange={(e) => setNewExperience({ ...newExperience, company: e.target.value })} />
                </div>
                <div className="col-md-3">
                  <label>Manager</label>
                  <input type="text" className="form-control" value={newExperience.manager} onChange={(e) => setNewExperience({ ...newExperience, manager: e.target.value })} />
                </div>
                <div className="col-md-3">
                  <label>Duration</label>
                  <input type="text" className="form-control" value={newExperience.duration} onChange={(e) => setNewExperience({ ...newExperience, duration: e.target.value })} />
                </div>
                <div className="col-12 mt-3">
                  <button className="btn btn-secondary" onClick={handleAddExperience}>Add Experience</button>
                </div>
              </div>

              {/* Display Added Experience */}
              {experiences.length > 0 && (
                <ul className="list-group mt-3">
                  {experiences.map((exp, index) => (
                    <li key={index} className="list-group-item">{exp.job} at {exp.company} (Manager: {exp.manager}, Duration: {exp.duration})</li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* References Section */}
          <div className="card shadow border-0 rounded mt-4">
            <div className="card-header fw-medium">References</div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <label>Full Name</label>
                  <input type="text" className="form-control" value={newReference.fullname} onChange={(e) => setNewReference({ ...newReference, fullname: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Email</label>
                  <input type="email" className="form-control" value={newReference.email} onChange={(e) => setNewReference({ ...newReference, email: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Position</label>
                  <input type="text" className="form-control" value={newReference.position} onChange={(e) => setNewReference({ ...newReference, position: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Organisation</label>
                  <input type="text" className="form-control" value={newReference.organisation} onChange={(e) => setNewReference({ ...newReference, organisation: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Contact Type</label>
                  <input type="text" className="form-control" value={newReference.contactType} onChange={(e) => setNewReference({ ...newReference, contactType: e.target.value })} />
                </div>
                <div className="col-md-4">
                  <label>Contact</label>
                  <input type="text" className="form-control" value={newReference.contact} onChange={(e) => setNewReference({ ...newReference, contact: e.target.value })} />
                </div>
                <div className="col-12 mt-3">
                  <button className="btn btn-secondary" onClick={handleAddReference}>Add Reference</button>
                </div>
              </div>

              {/* Display Added References */}
              {references.length > 0 && (
                <ul className="list-group mt-3">
                  {references.map((ref, index) => (
                    <li key={index} className="list-group-item">{ref.fullname} ({ref.email}) - {ref.position} at {ref.organisation} (Contact: {ref.contactType} - {ref.contact})</li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Requirements Section */}
          <div className="card shadow border-0 rounded mt-4">
            <div className="card-header fw-medium">Requirements</div>
            <div className="card-body">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="requirement1" value="Requirement 1" onChange={handleRequirementChange} />
                <label className="form-check-label" htmlFor="requirement1">Requirement 1</label>
              </div>
              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="requirement2" value="Requirement 2" onChange={handleRequirementChange} />
                <label className="form-check-label" htmlFor="requirement2">Requirement 2</label>
              </div>
              {/* Add more requirements as needed */}
            </div>
          </div>

          {/* Document Upload Section */}
          <div className="card shadow border-0 rounded mt-4">
            <div className="card-header fw-medium">Upload Document</div>
            <div className="card-body">
              <input type="file" className="form-control" onChange={handleDocumentChange} />
            </div>
          </div>

          {/* Submit Button */}
          <div className="mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      )}
    </>
  );
};

export default GreatForm;
