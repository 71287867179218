import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router for navigation

const FormSuccess = () => {
    return (
        <div className="container text-center mt-5">
            <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-check-circle text-success" width="3em" height="3em" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 0a10 10 0 110 20 10 10 0 010-20zm3.354 7.646a.5.5 0 00-.708-.708l-3 3a.5.5 0 00-.001.708l.001.001 1.5 1.5a.5.5 0 00.708-.001l4-4a.5.5 0 00-.001-.709l-.001-.001a.5.5 0 00-.709.001L13.5 9.793l-2.146-2.147z" clipRule="evenodd" />
            </svg>
            <p className="text-success mt-3">Success! Your Application has been submitted.</p>
            <Link to="/vacancies" className="btn btn-primary mt-3">Go to Vacancies</Link>
        </div>
    );
}

export default FormSuccess;