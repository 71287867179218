import { useQuery } from "@tanstack/react-query";
import { fetchAdverts } from "../apis/AdvertsFunction";
import VacancyList from "../features/VacancyList";
import { useState, useEffect } from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import PageTitle from "../components/PageTitle";
import PageButton from "../components/PageButton";
import { Link } from "react-router-dom";
import { useGetAdvertsQuery, advertsApi } from "../apis/advertsApi";
import AdvertSpinner from "../components/AdvertSpinner";
import "../search.css"


const VacancyAdvertListings = () => {
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("")

    const outerDivStyle = {
      backgroundColor: '#f5f7fa',
    };

    const sectionStyle = {
      maxWidth: '100vw',
    };
  

    //fetch adverts from provided endpoint
    const { data, isFetching, error, isLoading } = useGetAdvertsQuery();
    //console.log(data);
    const adverts = data?.data;


    // // Filter searched adverts
    // const handleSearch = (e) => {
    //   setSearchTerm(e.target.value);
    // };

    // Filter the adverts based on the search term
    // const filteredAdverts = adverts?.filter((advert) =>
    //   advert.title.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const filteredAdverts = adverts?.filter((advert) => {
      const closingDate = new Date(advert.closing_date);
      const today = new Date();
      return advert.title.toLowerCase().includes(searchTerm.toLowerCase()) && closingDate >= today;

    });
    

  

    // Handle search
  const handleSearch = async (e) => {
    e.preventDefault();
    await advertsApi.invalidateQueries('getAdverts', { refetchActive: true }); // Invalidate the 'getAdverts' query to trigger a refetch
  };

      //conditions
    if (isLoading) return <p><LoadingSpinner /></p>

    if (error) return <p>Network Error: {error.message}</p>

    return (
        <div className="" style={outerDivStyle}>
      <div className="container">
      <section className="mx-auto my-5" style={sectionStyle}>
        <div className="card">
          <div className="card-body">
            
            <blockquote className="blockquote blockquote-custom bg-white px-3 pt-4" style={{ position: 'relative', fontSize: '1.1rem' }}>
              <div className="blockquote-custom-icon bg-primary shadow-1-strong" style={{ width: '50px', height: '50px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: '-40px', left: '19px' }}>
                <i className="fa fa-quote-left text-white"></i>
              </div>
              <h2 className="card-title">How To Apply</h2>
              <p className="mb-0 mt-2 font-italic">
               <p>Use The Following Steps.</p>
               <ol>
               <li>View Advert</li>
               <li>Click Apply Button</li>
               <li>Fill in the Personal Details, Academic Qualifications and Work Experience Details.</li>
               <li> Send Application.</li>
               </ol>
              </p>
              <footer className="blockquote-footer pt-4 mt-4 border-top">
                Get Started with our Platform
                <cite title="Source Title">Today</cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>

    

    {/* <div className="container">

            <div className="row height d-flex justify-content-center align-items-center">

              <div className="col-md-6">

                <div className="form">
                  <i className="fa fa-search"></i>
                  <input type="text" className="form-control form-input"
                  onChange={handleSearch}
                   placeholder="Search Adverts..."/>
                  <span className="left-pan"><i className="fa fa-microphone"></i></span>
                </div>
                
              </div>
              
            </div>
            
          </div> */}

<div className="container">
      <div className="row height d-flex justify-content-center align-items-center">
        <div className="col-md-6">
          <div className="form">
            <i className="fa fa-search"></i>
            <form onSubmit={handleSearch}>
              <input
                type="text"
                className="form-control form-input"
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search Adverts..."
              />
              <span className="left-pan">
                <i className="fa fa-microphone"></i>
              </span>
              <button type="submit" style={{ display: 'none' }}></button>
            </form>
          </div>
        </div>
      </div>

      
       
        <div className="container">
          
          <div className="row pt-2">
          {/* <PageTitle icon="bi bi-journals text-primary" title="Job Adverts" /> */}

            <div>
              
          </div>
        </div>
      </div>
            
           {/* <div>
           {adverts &&  adverts.map((advert) => (<VacancyList advert={advert} key={advert.id} /> ))}
           
           </div> */}
           <div>
        {filteredAdverts &&
          filteredAdverts.map((advert) => (
            <VacancyList advert={advert} key={advert.id} />
          ))}
      </div>
        </div>
        </div>
    )
}
export default VacancyAdvertListings
