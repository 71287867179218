import React from 'react';
import { Col, Container, Row, Card, Spinner, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import PageTitle from '../../components/PageTitle';

const AdminApplicationDetail = () => {
    const { id } = useParams(); // Get the application ID from the URL params

    // Define the URL for fetching application data
    const url = `https://hrm.msu.ac.zw/api/v2/advert-applicant/${id}`;

    // Use React Query to fetch the application data
    const { data: application, isLoading, error } = useQuery(
        ['application', id],
        async () => {
            const response = await axios.get(url);
            return response.data;
        },
        {
            staleTime: 1000 * 60 * 5,
            refetchOnWindowFocus: false,
        }
    );

    // Display loading spinner while data is being fetched
    if (isLoading) {
        return (
            <div className="d-flex justify-content-center mt-4">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

    // Display error message if data fetching fails
    if (error) {
        return <Alert variant="danger" className="text-center">Error fetching application details</Alert>;
    }

    // Parse qualifications and experiences JSON strings
    const qualifications = JSON.parse(application.qualifications || '[]');
    const experiences = JSON.parse(application.experiences || '[]');

    return (
        <Container className="mt-4">
            <div className="container">
                <div className="row pt-2 mt-4">
                    <PageTitle icon="bi bi-people-fill" title="Application Detail" />
                    <div className="col-md-12 d-flex justify-content-center">
                        {/* Additional content can go here */}
                    </div>
                </div>
            </div>
            {/* Personal Details Section */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm mb-4">
                        <Card.Header className="bg-primary text-white fw-bold">Personal Details</Card.Header>
                        <Card.Body>
                            <p><strong>Name:</strong> {application.firstname} {application.surname}</p>
                            <p><strong>Email:</strong> {application.email_address}</p>
                            <p><strong>National ID:</strong> {application.national_id}</p>
                            <p><strong>Gender:</strong> {application.gender}</p>
                            <p><strong>Nationality:</strong> {application.nationality}</p>
                            <p><strong>Marital Status:</strong> {application.marital_status}</p>
                            <p><strong>Date of Birth:</strong> {application.dob}</p>
                            <p><strong>Contact Number:</strong> {application.contact_number}</p>
                            <p><strong>Experience:</strong> {application.experience} years</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Referrals Section */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm mb-4">
                        <Card.Header className="bg-primary text-white fw-bold">Applicant References</Card.Header>
                        <Card.Body>
                            <p><strong>Referral 1:</strong> {application.referral_name_1} ({application.referral_email_1})</p>
                            <p><strong>Referral 2:</strong> {application.referral_name_2} ({application.referral_email_2})</p>
                            <p><strong>Referral 3:</strong> {application.referral_name_3} ({application.referral_email_3})</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Documents Section */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm mb-4">
                        <Card.Header className="bg-primary text-white fw-bold">Applicant Document</Card.Header>
                        <Card.Body>
                            {/* <p>
                                <strong>CV:</strong> 
                                <a href={application.document_cv} target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">View CV</a>
                            </p>
                            <p>
                                <strong>National ID:</strong> 
                                <a href={application.document_national_id} target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">View National ID</a>
                            </p> */}
                            <p>
                                <strong>Qualification Document:</strong> 
                                <a href={application.document_qualification} target="_blank" rel="noopener noreferrer" className="ms-2 text-primary">Download CV</a>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Qualifications Section */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm mb-4">
                        <Card.Header className="bg-primary text-white fw-bold">Qualifications</Card.Header>
                        <Card.Body>
                            {qualifications.map((qualification, index) => (
                                <Card key={index} className="mb-3">
                                    <Card.Body>
                                        <p><strong>Institution:</strong> {qualification.institution}</p>
                                        <p><strong>Qualification:</strong> {qualification.qualification}</p>
                                        <p><strong>Grade:</strong> {qualification.grade}</p>
                                        <p><strong>Start Date:</strong> {qualification.startDate}</p>
                                        <p><strong>End Date:</strong> {qualification.endDate}</p>
                                    </Card.Body>
                                </Card>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Experiences Section */}
            <Row className="mb-4">
                <Col>
                    <Card className="shadow-sm mb-4">
                        <Card.Header className="bg-primary text-white fw-bold">Experiences</Card.Header>
                        <Card.Body>
                            {experiences.map((experience, index) => (
                                <Card key={index} className="mb-3">
                                    <Card.Body>
                                        <p><strong>Job Title:</strong> {experience.jobTitle}</p>
                                        <p><strong>Company:</strong> {experience.company}</p>
                                        <p><strong>Location:</strong> {experience.location}</p>
                                        <p><strong>Start Date:</strong> {experience.startDate}</p>
                                        <p><strong>End Date:</strong> {experience.endDate || 'Present'}</p>
                                        {/* <p><strong>Description:</strong> {experience.description}</p> */}
                                    </Card.Body>
                                </Card>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AdminApplicationDetail;
