import React from "react";
import WithNoScores from "../components/WithNoScores";
import { ApplicantsData } from "../apis/preshortListScores";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../components/LoadingSpinner";

export default function NoDocuments() {
    const { id } = useParams();

    const { isLoading, error, data: applicantsCandidates } = useQuery(
        ['applicantCandidates', id],
        () => ApplicantsData(id)
    );

    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="card card-primary">
        <div className="card-header fw-medium ">Applicants Data</div>
        <div className="card-body">
          {isLoading ? (
            <div className="loading-spinner">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <WithNoScores datas={applicantsCandidates} />
            </>
          )}
        </div>

      </div>


      
    );
}
