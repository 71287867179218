import React, { useState, useEffect } from "react";

const ApplicantContacts = ({ contacts, email }) => {
  // const [email, setEmail] = useState('');


    //  setEmail(contacts.email);
 
 

  const contactsArray = Array.isArray(contacts?.contacts) ? contacts.contacts : [];
  
  return (
    <div>
      <p>Email: <a href={`mailto:${email}`}>{email}</a></p>
      <table className="table mt-3">
        <thead>
          <tr>
            <th>Contact Type</th>
            <th>Contact</th>
          </tr>
        </thead>
        <tbody>
          {contactsArray.map((cont, index) => (
            <tr key={index}>
              <td>{cont.contact_type.name}</td>
              <td>{cont.contact}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApplicantContacts;
