import { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
// import LoadSpinner from './LoadSpinner';
import axios from 'axios';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup'
import { toast } from 'react-toastify';
import LoadSpinner from '../components/LoadSpinner';
import BootstrapSpinner from '../components/BootstrapSpinner';
import image from '../images/pic.jpg'

const isValidEmail = (email) => {
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
  };


const ApplicationForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [firstNameError, setfirstNameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [emailAddressError, setEmailAddressError] = useState('');
    const [nationalIDTextError, setNationalIDTextError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [nationalityError, setNationalityError] = useState('');
    const [maritalStatusError, setMaritalStatusError] = useState('');
    const [dobError, setDobError] = useState('');
    const [experienceError, setExperienceError] = useState('');
    const [advertIdError, setAdvertIdError] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');
    const [referral_name_1Error, setRefereeName1Error] = useState('');
    const [referral_email_1Error, setRefereeEmail1Error] = useState('');
    const [referral_contact_1Error, setRefereeContact1Error] = useState('');
    const [referral_name_2Error, setRefereeName2Error] = useState('');
    const [referral_email_2Error, setRefereeEmail2Error] = useState('');
    const [referral_contact_2Error, setRefereeContact2Error] = useState('');
    const [referral_name_3Error, setRefereeName3Error] = useState('');
    const [referral_email_3Error, setRefereeEmail3Error] = useState('');
    const [referral_contact_3Error, setRefereeContact3Error] = useState('');



    const [cvError, setCvError] = useState('');
    const [nationalIdError, setNationalIdError] = useState('');
    const [qualificationDocError, setQualificationDocError] = useState('');

//   const [advertId, setAdvertId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surname, setSurname] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [nationalId, setNationalId] = useState('');
  const [gender, setGender] = useState('');
  const [nationality, setNationality] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [dob, setDob] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [experience, setExperience] = useState('');
  const [referral_name_1, setRefereeName1] = useState('');
  const [referral_email_1, setRefereeEmail1] = useState('');
  const [referral_contact_1, setRefereeContact1] = useState('');
  const [referral_name_2, setRefereeName2] = useState('');
  const [referral_email_2, setRefereeEmail2] = useState('');
  const [referral_contact_2, setRefereeContact2] = useState('');
  const [referral_name_3, setRefereeName3] = useState('');
  const [referral_email_3, setRefereeEmail3] = useState('');
  const [referral_contact_3, setRefereeContact3] = useState('');
  const [cvFile, setCVFile] = useState(null);
  const [nationalIDFile, setNationalIDFile] = useState(null);
  const [qualificationFile, setQualificationFile] = useState(null);

  // Add the following state variables
  const [experienceJobTitle, setExperienceJobTitle] = useState('');
  const [experienceCompany, setExperienceCompany] = useState('');
  const [experienceLocation, setExperienceLocation] = useState('');
  const [experienceStartDate, setExperienceStartDate] = useState('');
  const [experienceEndDate, setExperienceEndDate] = useState('');


  const [experiences, setExperiences] = useState([]);
  const [jobTitleError, setJobTitleError] = useState('');
  const [experiencesError, setExperiencesError] = useState([]);
  const [companyError, setCompanyError] = useState([]);
  const [locationError, setLocationError] = useState('');
  const [experiencestartDateError, setExperienceStartDateError] = useState('');
  const [experienceendDateError, setExperienceEndDateError] = useState('');

  // ...

  const handleAddExperience = () => {
    
    if(!experienceJobTitle){
      setJobTitleError('Job title is required');
      return
    }
    if(!experienceCompany){
      setCompanyError('Company is required');
      return
    }
    if(!experienceLocation){
      setLocationError('Location is required');
      return
    }
    if(!experienceStartDate){
      setExperienceStartDateError('Start date is required');
      return
    }
    if(!experienceEndDate){
      setExperienceEndDateError('End date is required');
      return
    }
    if (experienceEndDate && experienceStartDate && new Date(experienceEndDate) < new Date(experienceStartDate)) {
      // toast.error('End date cannot be before start date');
      setExperienceEndDateError('End date cannot be before start date');
    } else {

      // Clear the error messages
    setJobTitleError('');
    setCompanyError('');
    setLocationError('');
    setExperienceStartDateError('');
    setExperienceEndDateError('');
    
    const experienceData = {
      jobTitle: experienceJobTitle,
      company: experienceCompany,
      location: experienceLocation,
      startDate: experienceStartDate,
      endDate: experienceEndDate,
    };

    setExperiences([...experiences, experienceData]);
    setExperienceJobTitle('');
    setExperienceCompany('');
    setExperienceLocation('');
    setExperienceStartDate('');
    setExperienceEndDate('');
  }
  };


  const [qualifications, setQualifications] = useState([]);
const [institution, setInstitution] = useState('');
const [qualification, setQualification] = useState('');
const [grade, setGrade] = useState('');
const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

// Add error state variables for each qualification field
const [institutionError, setInstitutionError] = useState('');
const [qualificationError, setQualificationError] = useState('');
const [gradeError, setGradeError] = useState('');
const [startDateError, setStartDateError] = useState('');
const [endDateError, setEndDateError] = useState('');


const handleAddQualification = () => {
  // Validate the qualification fields
  if (!institution) {
    setInstitutionError('Institution is required');
    return;
  }
  if (!qualification) {
    setQualificationError('Qualification is required');
    return;
  }
  if (!grade) {
    setGradeError('Grade is required');
    return;
  }
  if (!startDate) {
    setStartDateError('Start date is required');
    return;
  }
  if (!endDate) {
    setEndDateError('End date is required');
    return;
  }
  if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
    // toast.error('End date cannot be before start date');
    setEndDateError('End date cannot be before start date');
  } else{
    // Clear the error messages
  setInstitutionError('');
  setQualificationError('');
  setGradeError('');
  setStartDateError('');
  setEndDateError('');

  // Create a new qualification object
  const newQualification = {
    institution,
    qualification,
    grade,
    startDate,
    endDate,
  };

  // Add the new qualification to the qualifications array
  setQualifications([...qualifications, newQualification]);

  // Clear the input fields
  setInstitution('');
  setQualification('');
  setGrade('');
  setStartDate('');
  setEndDate('');

  }
  
};



    const formSubmit = async (event) => {
        event.preventDefault();


        const formData = new FormData();
        formData.append('advert_id', id);
        formData.append('firstname', firstName);
        formData.append('surname', surname);
        formData.append('email_address', emailAddress);
        formData.append('national_id', nationalId);
        formData.append('gender', gender);
        formData.append('nationality', nationality);
        formData.append('marital_status', maritalStatus);
        formData.append('dob', dob);
        formData.append('contact_number', contactNumber);
        formData.append('experience', experience);
        formData.append('referral_name_1', referral_name_1);
        formData.append('referral_name_2', referral_name_2);
        formData.append('referral_name_3', referral_name_3);
        formData.append('referral_email_1', referral_email_1);
        formData.append('referral_email_2', referral_email_2);
        formData.append('referral_email_3', referral_email_3);
        formData.append('referral_contact_1', referral_email_3);
        formData.append('referral_contact_2', referral_email_3);
        formData.append('referral_contact_3', referral_email_3);
        // formData.append('document_cv', cvFile);
        // formData.append('document_national_id', nationalIDFile);
        formData.append('document_qualification', qualificationFile);
        formData.append('qualifications', JSON.stringify(qualifications));
        formData.append('experiences', JSON.stringify(experiences));

       console.log("experiences test", experiences)

       console.log("qualifications test", qualifications)

        console.log(formData);
        // Log the individual key-value pairs to the console
        for (const [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        

        try {
            //Validations
            if (!firstName) {
                toast.error('First name is required');
                setfirstNameError('First name is required');
                return;
            }
            if (firstName.length < 2) {
                toast.error('First name must be at least 2 characters long');
                setfirstNameError('First name must be at least 2 characters long');
                return;
            }

            // Custom validation for surname
            if (!surname) {
                toast.error('Last name is required');
                setSurnameError('Last name is required');
                return;
            }
            if (surname.length < 2) {
                toast.error('Last name must be at least 2 characters long');
                setSurnameError('Last name must be at least 2 characters long');
                return;
            }

            if (!emailAddress) {
                toast.error('Valid Email is required');
                setEmailAddressError('Valid Email is required');
                return;
            }
            if (!isValidEmail(emailAddress)) {
                toast.error('Invalid email address');
                setEmailAddressError('Invalid email address');
                return;
            }
    
            // Custom validation for nationalId
            if (!nationalId) {
                toast.error('National ID is required');
                setNationalIDTextError('National ID is required');
                return;
            }

             // Custom validation for contactNumber
             if (!contactNumber) {
              toast.error('Contact number is required');
              setContactNumberError('Contact number is required');
              return;
          }
          if (contactNumber.length < 5) {
            toast.error('Contact number should be more than 5 characters!');
            setContactNumberError('Contact number should be at least 5 characters');
            return;
        }

        if (contactNumber.length > 15) {
          toast.error('Contact number should not exceed 15 characters!');
          setContactNumberError('Contact number should not exceed 15 characters');
          return;
      }

            // Custom validation for dob
            if (!experience) {
              toast.error('Number of job experience is required');
              setExperienceError('Number of job experience is required');
              return;
          } else if (experience > 40) {
              toast.error('Years of job experiences should not exceed 40');
              setExperienceError('Years of job experiences should not exceed 40');
              return;
          }

           // Custom validation for gender
           if (!gender) {
            toast.error('Gender is required');
            setGenderError('Gender is required');
            return;
        }

        // Custom validation for dob
        if (!dob) {
          toast.error('Date of birth is required');
          setDobError('Date of birth is required');
          return;
      }

      // Validate date of birth
      const selectedDate = new Date(dob);
      const currentDate = new Date();
      const minDate = new Date(currentDate.getFullYear() - 90, currentDate.getMonth(), currentDate.getDate());
      const maxDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());

      if (selectedDate < minDate || selectedDate > maxDate) {
        setDobError('Date of birth must be between 18 and 90 years old.');
        return;
      }

        // Custom validation for nationality
        if (!nationality) {
            toast.error('Nationality is required');
            setNationalityError('Nationality is required');
            return;
        }

        // Custom validation for maritalStatus
        if (!maritalStatus) {
            toast.error('Marital status is required');
            setMaritalStatusError('Marital status is required');
            return;
        }

            if (!referral_name_1) {
                toast.error('Referral name 1 is required');
                setRefereeName1Error('Referral name 1 is required');
                return;
              }
              if (!referral_email_1) {
                toast.error('Referral email 1 is required');
                setRefereeEmail1Error('Referral email 1 is required');
                return;
              }
              if (!referral_contact_1) {
                toast.error('Referral contact 1 is required');
                setRefereeContact1Error('Referral contact 1 is required');
                return;
              }
              if (referral_contact_1.length < 5) {
                toast.error('Contact number should be more than 5 characters!');
                setRefereeContact1Error('Contact number should be at least 5 characters');
                return;
            }
              if (!referral_name_2) {
                toast.error('Referral name 2 is required');
                setRefereeName2Error('Referral name 2 is required');
                return;
              }
              if (!referral_email_2) {
                toast.error('Referral email 2 is required');
                setRefereeEmail2Error('Referral email 2 is required');
                return;
              }
              if (!referral_contact_2) {
                toast.error('Referral contact 2 is required');
                setRefereeContact2Error('Referral contact 2 is required');
                return;
              }
              if (referral_contact_2.length < 5) {
                toast.error('Contact number should be more than 5 characters!');
                setRefereeContact2Error('Contact number should be at least 5 characters');
                return;
            }
              
              if (!referral_name_3) {
                toast.error('Referral name 3 is required');
                setRefereeName3Error('Referral name 3 is required');
                return;
              }
              if (!referral_email_3) {
                toast.error('Referral email 3 is required');
                setRefereeEmail3Error('Referral email 3 is required');
                return;
              }
              if (!referral_contact_3) {
                toast.error('Referral contact 3 is required');
                setRefereeContact3Error('Referral contact 3 is required');
                return;
              }
              if (referral_contact_3.length < 5) {
                toast.error('Contact number should be more than 5 characters!');
                setRefereeContact3Error('Contact number should be at least 5 characters');
                return;
            }
              // if (!cvFile) {
              //   toast.error('CV file is required');
              //   setCvError('CV file is required');
              //   return;
              // }

              // if (!nationalIDFile) {
              //   toast.error('National ID file is required');
              //   setNationalIdError('National ID file is required');
              //   return;
              // }

              if (!qualificationFile) {
                toast.error('Please provide Qualifications file');
                setQualificationDocError('Qualifications file is required');
                return;
              }

          setIsLoading(true);

          
          const response = await axios.post('https://hrm.msu.ac.zw/api/v2/application-form', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

          if (response.status === 200) {
            // Form submission successful
            // setAdvertId('');
            setFirstName('');
            setSurname('');
            setEmailAddress('');
            setNationalId('');
            setGender('');
            setNationality('');
            setMaritalStatus('');
            setDob('');
            setContactNumber('');
            setExperience('');
            setRefereeName1('');
            setRefereeName2('');
            setRefereeName3('');
            setRefereeEmail1('');
            setRefereeEmail2('');
            setRefereeEmail3('');
            setCVFile(null);
            console.log('Form submitted successfully');
            console.log(formData);
            setIsLoading(false);
            navigate(`/form-success`);
          } else {
            // Form submission failed
            console.error('Form submission failed');
            setIsLoading(false);
          }   
        } catch (error) {
          console.error('An error occurred while submitting the form:', error);
          toast.error('NOTE: Fill all the fields !');
          
          setIsLoading(false);
        }

        // navigate(`/`);
      };


      const handleCVFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.size > 50 * 1024 * 1024) {
            setCvError('File size exceeds the maximum limit of 5 MB.');
          } else {
            setCvError('');
            setCVFile(file);
            console.log('CV file selected:', file.name);
          }
        }
      };

      const handleNationalIDFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.size > 50 * 1024 * 1024) {
            setNationalIdError('File size exceeds the maximum limit of 5 MB.');
          } else {
            setNationalIdError('');
            setNationalIDFile(file);
          }
        }
      };

      const handleQualificationFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.size > 20 * 1024 * 1024) {
            setQualificationDocError('File size exceeds the maximum limit of 20 MB.');
          } else {
            setQualificationDocError('');
            setQualificationFile(file);
          }
        }
      };


  return (
    <div className=''>

<section class="h-100 ">
  <div class="container py-5 h-100">
    <form action="" onSubmit={formSubmit}>
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col">
        <div class="card card-registration my-4">
          <div class="row g-0">
            <div class=" d-none d-xl-block">
              {/* <img src={image}
                alt="Sample photo" class="img-fluid"
                style={{borderTopLeftRadius: '0.25rem', borderBottomLeftRadius: '0.25rem'}} /> */}
            </div>
            <div class="">
              <div class="card-body p-md-5 text-black">
                <h1 class="text-uppercase fw-bold text-primary">Application form</h1>
                <p className="mt-1 mb-5 small text-muted">
                To complete your application, please provide the information requested below.  
          </p>
          <hr className='mt-4 text-muted' />

          <div>
            <div class="row mt-4">
              <div class="col-md-6 mb-4">
                <h4 className='fw-medium'>Personal Information</h4>
                <p className="mt-1 mb-2 small text-muted">
                Use a permanent email address where you can receive mail.
          </p>
                </div>
                </div>
          </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label fw-medium" for="form3Example1m">First name</label>

                      <input type="text" name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)} class="form-control form-control-lg" />
                      {firstNameError && <span className="text-danger">{firstNameError}</span>}
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label fw-medium" for="form3Example1n">Last name</label>
                      <input type="text" name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  value={surname}
          onChange={(e) => setSurname(e.target.value)} class="form-control form-control-lg" />
                      {surnameError && <span className="text-danger">{surnameError}</span>}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label fw-medium" for="form3Example1m1">Email Address</label>
                      <input type="email"
                  autoComplete="email"
                  value={emailAddress}
          onChange={(e) => setEmailAddress(e.target.value)} id="form3Example1m1" class="form-control form-control-lg" />
                      {emailAddressError && <span className="text-danger">{emailAddressError}</span>}
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label fw-medium" for="form3Example1n1">National ID</label>
                      <input type="text"
                  autoComplete="text"
                  value={nationalId}
          onChange={(e) => setNationalId(e.target.value)} id="form3Example1n1" class="form-control form-control-lg" />
          {nationalIDTextError && <span className="text-danger">{nationalIDTextError}</span>}
                    </div>
                  </div>
                </div>

                <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label fw-medium" for="form3Example90">Enter Contact Number</label>

                  <input type="number"
                  autoComplete="text"
                  value={contactNumber}
          onChange={(e) => setContactNumber(e.target.value)} id="form3Example90" class="form-control form-control-lg" />
                  {contactNumberError && <span className="text-danger">{contactNumberError}</span>}
                </div>


                <div class="row">
                  <div class="col-md-4 mb-4">
                  <div className="mt-2">
                  <label for="dob" className="form-label fw-medium">Select Gender:</label>
                <select
                  id="gender"
                  name="gender"
                  autoComplete="gender-name"
                  value={gender}
          onChange={(e) => setGender(e.target.value)}

                  className="form-control form-control-lg"
                >
                  <option>Male</option>
                  <option>Female</option>
                </select>
            {genderError && <span className="text-danger">{genderError}</span>}

              </div>
                  </div>
                  <div className="col-md-4 mb-4">
                <label for="dob" className="form-label">Date of Birth</label>
                <input
            id="dob"
            type="date"
            min="1958-01-01"
            max="2006-12-31"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className="form-control form-control-lg"
          />
                 {dobError && <span className="text-danger">{dobError}</span>}
                </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-4">
                  <label for="dob" className="form-label">Nationality:  </label>

                    <select data-mdb-select-init name="nationality"
                  autoComplete="country-name"
                  value={nationality}
                  className="form-control form-control-lg"
          onChange={(e) => setNationality(e.target.value)}>
                      <option>Select Nationality</option>
                      <option>Zimbabwe</option>
                      <option>South Africa</option>
                      <option>Zambian</option>
                    </select>
                    {nationalityError && <span className="text-danger">{nationalityError}</span>}
                  </div>

                  <div class="col-md-4 mb-4">
                  <label for="dob" className="form-label">Marital Status:  </label>
                  <select
                  id="gender"
                  name="gender"
                  autoComplete="gender-name"
                  value={maritalStatus}
                  

          onChange={(e) => setMaritalStatus(e.target.value)}
                  className="form-control form-control-lg"
                >
                  <option>Select Marital Status</option>
                  <option value="single">Single</option>
                    <option value="married">Married</option>
                    <option value="divorced">Divorced</option>
                    <option value="widowed">Widowed</option>
                </select>
                {maritalStatusError && <span className="text-danger">{maritalStatusError}</span>}
                  </div>               
                </div>

                <hr className='mt-5 text-muted' />
                <div>
            <div class="row mt-4">
              <div class="col-md-6 mb-4">
                <h4 className='fw-medium mb-2'>Experience Information</h4>
                <p className='text-danger small'>Note: Click Add Experience Button After filling all experience fields</p>
                </div>
                </div>
          </div>


                <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form3Example9">Years of Experience</label>
                  <input type="number"
                  value={experience}
          onChange={(e) => setExperience(e.target.value)} id="form3Example9" class="form-control form-control-lg" />
                  {experienceError && <span className="text-danger">{experienceError}</span>}
                </div>

                <div className="row">
      <div className="col-md-12 mb-4">
        <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Job Title</label>
        <input
          type="text"
          name="job_title"
          id="job_title"
          autoComplete="text"
          value={experienceJobTitle}
          onChange={(e) => setExperienceJobTitle(e.target.value)}
          className="form-control form-control-md"
        />
        {jobTitleError && <span className="text-danger">{jobTitleError}</span>}
      </div>
    </div>

    <div className="row">
      <div className="col-md-12 mb-4">
        <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Company</label>
        <input
          type="text"
          name="company"
          id="company"
          autoComplete="text"
          value={experienceCompany}
          onChange={(e) => setExperienceCompany(e.target.value)}
          className="form-control form-control-md"
        />
        {companyError && <span className="text-danger">{companyError}</span>}
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 mb-4">
        <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Location</label>
        <input
          type="text"
          name="location"
          id="location"
          autoComplete="text"
          value={experienceLocation}
          onChange={(e) => setExperienceLocation(e.target.value)}
          className="form-control form-control-md"
        />
        {locationError && <span className="text-danger">{locationError}</span>}
      </div>
      <div className="col-md-6 mb-4">
        <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Start Date</label>
        <input
          type="date"
          name="start_date"
          id="start_date"
          value={experienceStartDate}
          onChange={(e) => setExperienceStartDate(e.target.value)}
          className="form-control form-control-md"
        />
        {experiencestartDateError && <span className="text-danger">{experiencestartDateError}</span>}
      </div>
    </div>

    <div className="row">
  <div className="col-md-6 mb-4">
    <label for="dob" className="text-sm font-medium text-gray-700 mb-1">End Date</label>
    <input
      type="date"
      name="end_date"
      id="end_date"
      value={experienceEndDate}
      onChange={(e) => setExperienceEndDate(e.target.value)}
      className="form-control form-control-md"
    />
    {experienceendDateError && <span className="text-danger">{experienceendDateError}</span>}
  </div>
  <div className="col-md-6 mb-4">
    <button type="button" onClick={handleAddExperience} className="btn btn-primary">
      Add Experience
    </button>
  </div>
</div>

    <div className="row">
      <div className="col-md-12 mb-4">
        <label for="dob" className="text-sm fw-medium text-gray-700 mb-1">Experiences:</label>
        <ul>
          {experiences.map((experience, index) => (
            <li key={index}>
              {experience.jobTitle} - {experience.company} - {experience.location} - {experience.startDate} - {experience.endDate}
            </li>
          ))}
        </ul>
      </div>
    </div>

    <div>
            <div class="row mt-4">
              <div class="col-md-6 mb-4">
                <h4 className='fw-medium mb-2'>Qualifications Information</h4>
                <p className="mt-1 mb-2 small text-danger">
            Note: Click Add Qualifications Button After filling all qualifications fields
          </p>
                </div>
                </div>
          </div>


    <div>
  <div className="row">
    <div className="col-md-4 mb-4">
      <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Institution</label>
      <input
        type="text"
        name="institution"
        id="institution"
        autoComplete="text"
        value={institution}
        onChange={(e) => setInstitution(e.target.value)}
        className="form-control form-control-md"
      />
      {institutionError && <span className="text-danger">{institutionError}</span>}
    </div>
    <div className="col-md-4 mb-4">
      <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Qualification</label>
      <input
        type="text"
        name="qualification"
        id="qualification"
        autoComplete="text"
        value={qualification}
        onChange={(e) => setQualification(e.target.value)}
        className="form-control form-control-md"
      />
      {qualificationError && <span className="text-danger">{qualificationError}</span>}
    </div>
    <div className="col-md-4 mb-4">
      <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Grade</label>
      <input
        type="text"
        name="grade"
        id="grade"
        autoComplete="text"
        value={grade}
        onChange={(e) => setGrade(e.target.value)}
        className="form-control form-control-md"
      />
      {gradeError && <span className="text-danger">{gradeError}</span>}
    </div>
  </div>

  <div className="row">
    <div className="col-md-4 mb-4">
      <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Start Date</label>
      <input
        type="date"
        name="start_date"
        id="start_date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="form-control form-control-md"
      />
      {startDateError && <span className="text-danger">{startDateError}</span>}
    </div>
    <div className="col-md-4 mb-4">
      <label for="dob" className="text-sm font-medium text-gray-700 mb-1">End Date</label>
      <input
        type="date"
        name="end_date"
        id="end_date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="form-control form-control-md"
      />
      {endDateError && <span className="text-danger">{endDateError}</span>}
    </div>
    <div className="col-md-4 mb-4">
      <button type="button" onClick={handleAddQualification} className="btn btn-primary">
        Add Qualification
      </button>
    </div>
  </div>
</div>

<div>
  <div class="row mt-4">
    <div class="col-md-12 mb-4">
      <h5 className='fw-medium mb-2'>Qualifications:</h5>
    </div>
  </div>

  <div class="row">
    <div className="col-md-12 mb-4">
      <ul>
        {qualifications.map((qualification, index) => (
          <li key={index}>
            {qualification.institution} - {qualification.qualification} - {qualification.grade} - {qualification.startDate} - {qualification.endDate}
          </li>
        ))}
      </ul>
    </div>
  </div>
</div>
    

    <div>
            <div class="row mt-4">
              <div class="col-md-6 mb-4">
                <h4 className='fw-medium mb-2'>Referees</h4>
                <p className="mt-1 mb-2 small text-muted">
            NB: Enter any three Referees below
          </p>
                </div>
                </div>
          </div>


                <div class="row">
                <div className="col-md-4 mb-4">
                <label for="dob" className="">Referee Name 1</label>
              
            <input
            type="text"
            name="referral_name_1"
            id="referral_name_1"
            autoComplete="text"
            value={referral_name_1}
            onChange={(e) => setRefereeName1(e.target.value)}
            
            className="form-control form-control-md"
            />
            {referral_name_1Error && <span className="text-danger">{referral_name_1Error}</span>}
                </div>
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Email</label>
                <input
        type="email"
        name="referral_email_1"
        id="referral_email_1"
        autoComplete="given-referral_email_1"
        value={referral_email_1}
        onChange={(e) => setRefereeEmail1(e.target.value)}

        className="form-control form-control-md"
        />
        {referral_email_1Error && <span className="text-danger">{referral_email_1Error}</span>}
                </div>
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Contact</label>
                <input
        type="number"
        name="refferal_contact_1"
        id="refferal_contact_1"
        autoComplete="given-referral_contact_1"
        value={referral_contact_1}
        onChange={(e) => setRefereeContact1(e.target.value)}

        className="form-control form-control-md"
        />
        {referral_contact_1Error && <span className="text-danger">{referral_contact_1Error}</span>}
                </div>
                </div>

                <div class="row">
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Name 2</label>
            <input
            type="text"
            name="referral_name_1"
            id="referral_name_1"
            autoComplete="text"
            value={referral_name_2}
            onChange={(e) => setRefereeName2(e.target.value)}
            
            className="form-control form-control-md"
            />
            {referral_name_1Error && <span className="text-danger">{referral_name_2Error}</span>}
                </div>
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Email</label>
                <input
        type="email"
        name="referral_email_1"
        id="referral_email_1"
        autoComplete="given-referral_email_1"
        value={referral_email_2}
        onChange={(e) => setRefereeEmail2(e.target.value)}

        className="form-control form-control-md"
        />
        {referral_email_1Error && <span className="text-danger">{referral_email_2Error}</span>}
                </div>
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Contact</label>
                <input
        type="number"
        name="refferal_contact_1"
        id="refferal_contact_1"
        autoComplete="given-referral_contact_1"
        value={referral_contact_2}
        onChange={(e) => setRefereeContact2(e.target.value)}

        className="form-control form-control-md"
        />
        {referral_contact_1Error && <span className="text-danger">{referral_contact_2Error}</span>}
                </div>
                </div>

                
                <div class="row">
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Name 3</label>
            <input
            type="text"
            name="referral_name_1"
            id="referral_name_1"
            autoComplete="text"
            value={referral_name_3}
            onChange={(e) => setRefereeName3(e.target.value)}
            
            className="form-control form-control-md"
            />
            {referral_name_1Error && <span className="text-danger">{referral_name_3Error}</span>}
                </div>
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Email</label>
                <input
        type="email"
        name="referral_email_1"
        id="referral_email_1"
        autoComplete="given-referral_email_1"
        value={referral_email_3}
        onChange={(e) => setRefereeEmail3(e.target.value)}

        className="form-control form-control-md"
        />
        {referral_email_1Error && <span className="text-danger">{referral_email_3Error}</span>}
                </div>
                <div className="col-md-4 mb-4">
                <label for="dob" className="text-sm font-medium text-gray-700 mb-1">Referee Contact</label>
                <input
        type="number"
        name="refferal_contact_1"
        id="refferal_contact_1"
        autoComplete="given-referral_contact_1"
        value={referral_contact_3}
        onChange={(e) => setRefereeContact3(e.target.value)}

        className="form-control form-control-md"
        />
        {referral_contact_1Error && <span className="text-danger">{referral_contact_3Error}</span>}
                </div>
                </div>

                <div>
            <div class="row mt-4">
              <div class="col-md-6 mb-4">
                <h4 className='fw-medium mb-2'>Uploads</h4>
                <div class="text-danger fw-medium">NB: Upload all your qualifications in a single pdf file</div>
                </div>
                </div>
          </div>


                {/* <div data-mdb-input-init class="form-outline mb-4">
                
                  <input class="form-control form-control-lg" onChange={handleCVFileChange} id="formFileLg" type="file" accept="application/pdf" />
                  {cvError && <span className="text-danger">{cvError}</span>}
                  <div class="small text-muted mt-2">Upload your CV. Max file
                size 5 MB</div>
                
                </div>

                <div data-mdb-input-init class="form-outline mb-4">
                  <input class="form-control form-control-lg" id="formFileLg" onChange={handleNationalIDFileChange} type="file" accept="application/pdf" />
                  {nationalIdError && <span className="text-danger">{nationalIdError}</span>}
                <div class="small text-muted mt-2">Upload National ID. Max file
                  size 5 MB</div>
                </div> */}

                <div data-mdb-input-init class="form-outline mb-4">
                  <input class="form-control form-control-lg" id="formFileLg" onChange={handleQualificationFileChange}
 type="file" accept="application/pdf" />
 {qualificationDocError && <span className="text-danger">{qualificationDocError}</span>}
                </div>

                <div class="d-flex justify-content-end pt-3">
                  {/* <button  type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-light btn-lg">Reset all</button>
                  <button  type="submit" data-mdb-button-init data-mdb-ripple-init class="btn btn-warning btn-lg ms-2">Submit form</button> */}
                  <div class="">
              <button type="submit" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-lg ms-2">Send Application
              </button>
            </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
  </div>
</section>


        
    {isLoading && <BootstrapSpinner />}

    </div>
  );
};




export default ApplicationForm;