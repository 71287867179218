import { useQuery } from "@tanstack/react-query";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageTitle from "../../components/PageTitle";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";

const AdvertApplicants = () => {
    const { id } = useParams(); // Use destructuring to get id directly
    const navigate = useNavigate();

    const url = `https://hrm.msu.ac.zw/api/v2/advert-applicants/${id}`;

    const { data: applicants, isLoading, error } = useQuery(
        ['applicants', id], // Include id in the query key to refetch data when id changes
        async () => {
            const response = await axios.get(url);
            return response.data;
        }
    );

    // Handle navigation
    const handleNavigate = (applicantId) => {
        navigate(`/admin/application-detail/${applicantId}`);
    };

    // Render loading spinner while data is being fetched
    if (isLoading) {
        return <LoadingSpinner />;
    }

    // Display error message if data fetching fails
    if (error) {
        // Check if the error has a response with a message from the API
        const errorMessage = error.response?.data?.message || 'Error fetching data';
        return <div className="alert alert-danger">{errorMessage}</div>;
    }

    // Check for API message in applicants data if no applicants are found
    const apiMessage = Array.isArray(applicants) && applicants.length === 0 ? 'No applications found for this advert.' : null;

    return (
        <>
            <div className="container">
                <div className="row pt-2 mt-4">
                    <PageTitle icon="bi bi-people-fill" title="Advert Applications" />
                    <div className="col-md-12 d-flex justify-right mb-3">
                        {/* Summary Table Link */}
                        <Link to={`/admin-summary-table/${id}`} className="btn btn-primary">
                            Summary Table
                        </Link>
                    </div>
                </div>

            </div>

            <div className="container">
                <div className="card">
                    <div className="card-body">
                        {apiMessage && (
                            <div className="alert alert-info text-center">
                                {apiMessage}
                            </div>
                        )}
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Surname</th>
                                    <th>Gender</th>
                                    <th>Email</th>
                                    <th>National ID</th>
                                    <th>Contact</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applicants && applicants.length > 0 ? (
                                    applicants.map((applicant) => (
                                        <tr key={applicant.id}>
                                            <td>{applicant.firstname}</td>
                                            <td>{applicant.surname}</td>
                                            <td>{applicant.gender}</td>
                                            <td>{applicant.email_address}</td>
                                            <td>{applicant.national_id}</td>
                                            <td>{applicant.contact_number}</td>
                                            <td>
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handleNavigate(applicant.id)}
                                                >
                                                    View
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdvertApplicants;
