import { useSelector, useDispatch } from "react-redux";
import PageTitle from "../components/PageTitle";

import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";

const HowToApply = () =>{
//this is advert id


    return(

      <>
      <div className="container">
        <div className="card">
            <h4 className="card-header">How To Apply</h4>
            <div className="card-body">
                
            </div>
        </div>
      </div>
      </>
      
    )
}

export default HowToApply;